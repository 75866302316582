<template>
  <div :class="`address-count-${layout}`">
    <div
			v-if="variant !== variants.TELEMARKETING"
			class="address-count-entry address-count-address"
			:title="$t('Number of addresses')">
      <v-icon small>fas fa-envelope</v-icon>
      <div class="address-count-value">{{ addressCount }}</div>
    </div>
		<span v-if="variant !== variants.TELEMARKETING && layout === 'row' && enablePhoneNumbers">|</span>
    <div v-if="enablePhoneNumbers" class="address-count-entry address-count-phone" :title="$t('Number of phone numbers')">
      <v-icon small>fas fa-phone</v-icon>
      <div class="address-count-value">{{ phoneCount }}</div>
    </div>
  </div>
</template>

<script>
import * as variants from '../../../constants/variants'

export default {
	name: 'AddressCount',
	data: () => ({
		variants,
	}),
	props: {
		layout: {
			type: String,
			default: 'row',
			validator: (value) => ['row', 'column'].includes(value),
		},
		addressCount: {
			type: Number,
		},
		phoneCount: {
			type: Number,
		},
		variant: {
			type: String,
		},
	},
	computed: {
		enablePhoneNumbers() {
			return this.$store.state.session.overrideModules.enablePhoneNumbers
		},
	},
}
</script>
<style lang="scss" scoped>
@import "./AddressCount.scss";
</style>